.logo-animation-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 16px;
  gap: 2px;
  -webkit-filter: drop-shadow(1px 2px 3px rgba(0, 0, 0, 0.75));
  filter: drop-shadow(1px 2px 3px rgba(0, 0, 0, 0.75));

  img {
    opacity: 0;
    height: 48px;
  }
}

@media (max-width: 768px) {
  .logo-animation-wrapper {
    img {
      height: 32px;
    }
  }
}
