.about-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  text-wrap: pretty;
  background-color: #f2f1f0;

  p {
    font-size: 32px;
    text-align: left;
    text-wrap: pretty;
    line-height: 1.35;
  }
}

.about-quote-wrapper {
  overflow: hidden;
  h3 {
    font-size: 40px;
    text-align: left;
    line-height: 1.15;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 85%;
    margin: 0 auto;
    font-style: italic;
  }

  span {
    font-size: 32px;
    font-style: normal;
  }
}

.about-image-container {
  img {
    border: 2px solid black;
  }
}

@media (max-width: 768px) {
  .about-container {
    flex-direction: column;

    p {
      font-size: 18px;
    }
  }

  .about-quote-wrapper {
    h3 {
      font-size: 24px;
      line-height: 1.15;
      width: 100%;
    }

    span {
      font-size: 24px;
    }
  }
}
