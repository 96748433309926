.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.65);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  border: 2px solid black;
  background-color: #f2f1f0;
  overflow-y: scroll;
  min-width: 350px;
}

.modal-header {
  position: relative;
  height: 50px;
  border-bottom: 2px solid black;
  background-color: #f2f1f0;
}

.modal-title {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 20px;
  font-weight: 400;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.close-button {
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
  border: none;
  background: none;
  text-decoration: none;
  transition: all 0.25s ease-in-out;
}

.close-button:hover {
  scale: 1.15;
}

.dash-info-line {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 8px;
  text-wrap: nowrap;
}

.dash-info-line select,
.dash-info-line input {
  width: 50%;
  text-align: center;
}

@media (max-width: 768px) {
  .modal-content {
    width: calc(100vw - 32px);
  }
}
