.edit-event-container {
  width: 100%;
  display: flex;
  padding: 8px;
}

.edit-event-form {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
}

.edit-event-form-delete {
  color: red;
  text-transform: uppercase;

  input[type="checkbox"].toggle:checked:after {
    background: red;
    border: 2px solid black;
  }
}
