.gallery-container {
  background-color: #f2f1f0;
  padding-top: 55px;
  padding-bottom: 32px;
  img {
    border: 2px solid black;
    opacity: 0;
  }
  width: 100vw;
  min-height: calc(100vh - 55px);
  padding: 55px 16px 16px 16px;
  box-sizing: border-box;
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100svh;
  width: 100vw;
  background-color: #f2f1f0;
}
