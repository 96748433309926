.title-container {
  width: 100%;
  overflow: hidden;
  position: relative;
  display: flex;
  justify-content: end;
  align-items: center;
}

.title {
  width: fit-content;
  font-size: 120px;
  color: black;
  font-weight: 700;
  position: absolute;
  left: 0;
  right: 0;
  top: 32px;
  bottom: 0;
  text-align: center;
  line-height: 1;
  padding: 0 16px;
  text-wrap: nowrap;
}

.title-wrapper-line {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 2px;
  background-color: black;
}

@media (max-width: 768px) {
  .title {
    font-size: 48px;
    top: 8px;
  }
}
