.checkbox-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.checkbox-text {
  width: auto;
  padding-right: 4px;
  text-wrap: balance;
  font-size: 14px;
  line-height: 1.5;
}

input[type="checkbox"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-tap-highlight-color: transparent;
  cursor: pointer;
  width: 60px;
  height: 32px;
  border-radius: 30px;
  z-index: 2;
  transition: all 0.25s ease-in-out;
  background: #f2f1f0;
}

input[type="checkbox"]:focus {
  outline: 0;
}

.toggle {
  box-sizing: border-box;
  height: 32px;
  width: 60px;
  display: inline-block;
  position: relative;
  margin: 0;
  border: 2px solid black;
  cursor: pointer;
}

.toggle:after {
  content: "";
  position: absolute;
  top: 2px;
  left: 2px;
  width: 24px;
  height: 24px;
  border-radius: 30px;
  border: 2px solid transparent;
  transition: all 0.25s ease-in-out;
  background: #f2f1f0;
  border: 2px solid black;
  box-shadow: 1.15px 1.15px 1.15px rgba(0, 0, 0, 0.35);
}

input[type="checkbox"].toggle:checked:after {
  border: 2px solid transparent;
  border-radius: 30px;
  background: black;
  transition: all 0.15s ease-in-out;
  transform: translateX(26px);
  box-shadow: 1.15px 1.15px 1.15px rgba(0, 0, 0, 0.45);
}

@media (max-width: 768px) {
  .checkbox-text {
    font-size: 12px;
    line-height: 1.35;
  }
}
