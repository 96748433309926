.header-container {
  position: fixed;
  top: 0;
  z-index: 700;
  height: 55px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  mix-blend-mode: difference;
}

.menu-button {
  display: flex;
  padding-left: 16px;
  color: #f2f1f0;
  cursor: pointer;
}

.menu-button.open {
  transition: all 0.3s ease-in-out;
  opacity: 0;
}

.header-logo {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  height: 32px;
  width: auto;
  filter: invert(1);
}
